<template>
  <section id="contacts" class="py-14 dark:bg-gray-dark lg:py-[100px]">
    <div class="container">
      <div class="relative z-10 lg:flex">
        <div class="heading text-center lg:mb-0 lg:w-1/3 ltr:lg:pr-10 ltr:lg:text-left rtl:lg:pl-10 rtl:lg:text-right">
          <h4 class="sm:!leading-[50px]">Остались вопросы?</h4>
          <h5>Будем на связи</h5>
          <img src="/assets/images/form-img.png" alt="form-img" class="mx-auto"
            :data-aos="store.direction === 'rtl' ? 'fade-left' : 'fade-right'" data-aos-duration="1000" />
        </div>

        <ContactsForm></ContactsForm>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useAppStore } from "@/stores/index";
const store = useAppStore();
</script>
