<template>
  <form
    class="rounded-3xl bg-white px-4 py-12 dark:bg-[#101626] lg:w-2/3 lg:px-8"
    enctype="multipart/form-data"
  >
    <div class="grid gap-10 sm:grid-cols-2">
      <div class="form-field">
        <div class="relative">
          <input
            v-model="formData.name"
            id="userName"
            name="name"
            type="text"
            required
            class="w-full rounded-2xl border-2 border-gray/20 bg-transparent p-4 font-bold outline-none transition ltr:pr-12 rtl:pl-12"
          />
          <label
            for="userName"
            class="absolute -top-3 bg-white px-2 font-bold text-sm ltr:left-6 rtl:right-6 dark:bg-[#101626] dark:text-white">Имя</label>
          <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="absolute top-1/2 -translate-y-1/2 ltr:right-4 rtl:left-4 dark:text-white">
            <path
              d="M5.42855 5.57875C5.42855 8.10348 7.47525 10.1502 9.99998 10.1502C12.5247 10.1502 14.5714 8.10348 14.5714 5.57875C14.5714 3.05402 12.5247 1.00732 9.99998 1.00732"
              stroke="currentColor" stroke-width="1.8" stroke-linecap="round" />
            <path
              d="M2 16.9328C2 15.9495 2.61812 15.0724 3.5441 14.7417V14.7417C7.71891 13.2507 12.2811 13.2507 16.4559 14.7417V14.7417C17.3819 15.0724 18 15.9495 18 16.9328V18.7014C18 19.9185 16.922 20.8535 15.7172 20.6813L13.8184 20.4101C11.2856 20.0483 8.71435 20.0483 6.18162 20.4101L4.28284 20.6813C3.07798 20.8535 2 19.9185 2 18.7014V16.9328Z"
              stroke="currentColor" stroke-width="1.8" />
          </svg>
        </div>
      </div>

      <div class="form-field">
        <div class="relative">
          <input type="email" name="email" v-model="formData.email"
            class="w-full rounded-2xl border-2 border-gray/20 bg-transparent p-4 font-bold outline-none transition focus:border-secondary ltr:pr-12 rtl:pl-12" />
          <label for=""
            class="absolute -top-3 bg-white px-2 font-bold text-sm ltr:left-6 rtl:right-6 dark:bg-[#101626] dark:text-white">Email
          </label>
          <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="absolute top-1/2 -translate-y-1/2 ltr:right-4 rtl:left-4 dark:text-white">
            <path
              d="M1 8.00732V7.00732C1 4.2459 3.23858 2.00732 6 2.00732H16C18.7614 2.00732 21 4.2459 21 7.00732V13.0073C21 15.7687 18.7614 18.0073 16 18.0073H6C3.23858 18.0073 1 15.7687 1 13.0073V12.0073"
              stroke="currentColor" stroke-width="1.8" stroke-linecap="round" />
            <path d="M5 7.00732L9.8 10.6073C10.5111 11.1407 11.4889 11.1407 12.2 10.6073L17 7.00732" stroke="currentColor"
              stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>

        <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">
          Мы не шлем никакого спама
        </p>
      </div>

      <div class="form-field col-span-2">
        <div class="relative">
          <input type="text" name="phone" v-model="formData.phone"
            class="w-full rounded-2xl border-2 border-gray/20 bg-transparent p-4 font-bold outline-none transition focus:border-secondary ltr:pr-12 rtl:pl-12" />
          <label for=""
            class="absolute -top-3 bg-white px-2 font-bold text-sm ltr:left-6 rtl:right-6 dark:bg-[#101626] dark:text-white">Телефон</label>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="absolute top-1/2 -translate-y-1/2 ltr:right-4 rtl:left-4 dark:text-white">
            <path
              d="M6.45241 1.40806C5.45292 0.783702 4.14202 0.887138 3.2983 1.73086L1.86856 3.1606C-0.302899 5.33207 1.73747 10.8931 6.42586 15.5815C11.1142 20.2699 16.6753 22.3102 18.8467 20.1388L20.2765 18.709C21.2635 17.722 21.2374 16.0956 20.2182 15.0764L18.0036 12.8619C16.9844 11.8426 15.358 11.8165 14.371 12.8036L14.0639 13.1107C13.531 13.6436 12.6713 13.6957 12.0713 13.2005C11.4925 12.7229 10.9159 12.208 10.3576 11.6497C9.79933 11.0914 9.28441 10.5149 8.80678 9.93607C8.31161 9.33601 8.36374 8.47631 8.89666 7.9434L9.20375 7.63631C9.98187 6.85819 10.1303 5.68271 9.65898 4.72062"
              stroke="currentColor" stroke-width="1.8" stroke-linecap="round" />
          </svg>
        </div>
      </div>

      <div class="form-field col-span-2">
        <div class="relative">
          <input
            id="userFile"
            type="file"
            name="file"
            class="w-full rounded-2xl border-2 border-gray/20 bg-transparent p-4 font-bold outline-none transition focus:border-secondary ltr:pr-12 rtl:pl-12"
            @change="changeFile"
          />

          <label
            for="userFile"
            class="absolute -top-3 bg-white px-2 font-bold text-sm ltr:left-6 rtl:right-6 dark:bg-[#101626] dark:text-white"
          >
            Любой файл
          </label>

          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="absolute top-1/2 -translate-y-1/2 ltr:right-4 rtl:left-4 dark:text-white">
            <path
              d="M6.45241 1.40806C5.45292 0.783702 4.14202 0.887138 3.2983 1.73086L1.86856 3.1606C-0.302899 5.33207 1.73747 10.8931 6.42586 15.5815C11.1142 20.2699 16.6753 22.3102 18.8467 20.1388L20.2765 18.709C21.2635 17.722 21.2374 16.0956 20.2182 15.0764L18.0036 12.8619C16.9844 11.8426 15.358 11.8165 14.371 12.8036L14.0639 13.1107C13.531 13.6436 12.6713 13.6957 12.0713 13.2005C11.4925 12.7229 10.9159 12.208 10.3576 11.6497C9.79933 11.0914 9.28441 10.5149 8.80678 9.93607C8.31161 9.33601 8.36374 8.47631 8.89666 7.9434L9.20375 7.63631C9.98187 6.85819 10.1303 5.68271 9.65898 4.72062"
              stroke="currentColor" stroke-width="1.8" stroke-linecap="round" />
          </svg>
        </div>

        <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">
          Пример оборудования в удобном для вас формате
        </p>
      </div>

    </div>
    <div class="relative mt-10">
      <textarea name="message" v-model="formData.message"
        class="w-full rounded-2xl border-2 border-gray/20 bg-transparent p-4 font-bold outline-none transition focus:border-secondary ltr:pr-12 rtl:pl-12" />
      <label for=""
        class="absolute -top-3 bg-white px-2 font-bold text-sm ltr:left-6 rtl:right-6 dark:bg-[#101626] dark:text-white">Сообщение</label>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
        class="absolute top-1/2 -translate-y-1/2 ltr:right-4 rtl:left-4 dark:text-white">
        <path
          d="M1 11.467V18.9267C1 19.7652 1.96993 20.2314 2.6247 19.7076L5.45217 17.4456C5.8068 17.1619 6.24742 17.0073 6.70156 17.0073H16C18.7614 17.0073 21 14.7687 21 12.0073V6.00732C21 3.2459 18.7614 1.00732 16 1.00732H6C3.23858 1.00732 1 3.2459 1 6.00732V7.62225"
          stroke="currentColor" stroke-width="1.8" stroke-linecap="round" />
        <circle cx="6.05005" cy="9.05713" r="1.25" fill="currentColor" />
        <circle cx="11.05" cy="9.05713" r="1.25" fill="currentColor" />
        <circle cx="16.05" cy="9.05713" r="1.25" fill="currentColor" />
      </svg>
    </div>

    <div class="mt-10 text-center ltr:lg:text-right rtl:lg:text-left">
      <button type="button" @click="contactsSubmitForm"
        class="btn bg-gray px-12 capitalize text-white dark:bg-white dark:text-black dark:hover:bg-secondary">
        Отправить
      </button>
    </div>
  </form>
</template>

<script setup>
import { useAppStore } from "@/stores/index";

import { reactive, toRaw } from "vue";

// const { $metrika } = useNuxtApp()

const mail = useMail()


const route = useRoute()
const store = useAppStore();

const phoneCookie = useCookie('current_phone')
const utmSourceCookie = useCookie('utm_source')

const runtimeConfig = useRuntimeConfig()
const strapiUrl = runtimeConfig.public.strapi.url
const body = new FormData();

const formData = reactive({
  name: '',
  email: '',
  phone: '',
  message: '',
  url: route.path,
  phoneFromCookie: phoneCookie.value,
  utmSourceFromCookie: utmSourceCookie.value,
  file: '',
})

const changeFile = async (e) => {
  try {
    body.append('files', e.target.files[0])

    const response = await fetch(`${strapiUrl}/api/upload`, {
      method: 'post',
      body: body
    });

    const json = await response.json();

    formData.file = `${strapiUrl}${json[0].url}`
  } catch (e) {
    console.error(e)
  }
}

const contactsSubmitForm = () => {
  console.log('contactsSubmitForm')

  try {
    ym(94139854, 'reachGoal', 'form_before_submitted')
  } catch (err) {
    console.log('$metrika error')
  }

  if (formData.email || formData.phone) {
    try {
      ym(94139854, 'reachGoal', 'form_validation_passed')
    } catch (err) {
      console.log('$metrika error')
    }


    let objectDate = new Date();
    let day = objectDate.getDate();
    let month = objectDate.getMonth() + 1;
    let year = objectDate.getFullYear();

    if (day < 10) {
      day = '0' + day;
    }

    if (month < 10) {
      month = `0${month}`;
    }

    let format1 = `${year}/${month}/${day}`;

    mail.send({
      subject: `Заказ с сайта DarkEvent.ru (${format1})`,
      text: 'This is an incredible test message',
      html: `<pre>${JSON.stringify(toRaw(formData), null, 4)}</pre>`
    })

    try {
      ym(94139854, 'reachGoal', 'form_mail_sent')
    } catch (err) {
      console.log('$metrika error')
    }

    store.contactsSubmitForm(formData)
  } else {
    try {
      ym(94139854, 'reachGoal', 'form_validation_not_passed')
    } catch (err) {
      console.log('$metrika error')
    }

    alert('Заполните, пожалуйста, номер телефона или адрес электронной почты')
  }
}
</script>
