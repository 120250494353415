<template>
  <section id="contacts" class="py-14 dark:bg-gray-dark lg:py-[100px]">
    <div class="container">
      <div class="relative z-10 lg:flex">
        <div class="heading text-center lg:mb-0 lg:w-full ltr:lg:pr-10 ltr:lg:text-center">
          <h6>Сообщение отправлено</h6>
          <h4 class="sm:!leading-[50px]">Наш менеджер свяжется с вами в ближайшее время</h4>
          <img src="/assets/images/form-img.png" alt="form-img" class="mx-auto" data-aos="fade-left"
            data-aos-duration="1000" />
          <a @click="store.contactsReset($event)" href=""
            class="text-center router-link-active router-link-exact-active btn bg-cyan mx-auto mt-7 block w-fit text-white lg:rtl:ml-auto">
            Отправить ещё одно</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useAppStore } from "@/stores/index";
const store = useAppStore();
</script>
